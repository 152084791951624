import React from "../../../../hooks/reactImport"
// import { Elements, LinkAuthenticationElement } from "@stripe/react-stripe-js"
import CheckoutForm from "./CheckoutForm"
// import { loadStripe } from "@stripe/stripe-js"
import https from "../../../../services/https"
import { API_URL } from "../../../../primeagile.config"
// import { AddressElement } from "@stripe/react-stripe-js"
import { useSelector } from "react-redux"
import { styledCompoent } from "../../../../styledComponent"
import { Suspense, useEffect, useState } from "../../../../hooks/stateHooks"
import {
  Elements,
  LinkAuthenticationElement,
  AddressElement,
} from "@stripe/react-stripe-js"
// const loadStripe = loadable(() => import("@stripe/stripe-js"))

const loadStripe = await import("@stripe/stripe-js").then(module => {
  return module.loadStripe
})

const stripePromise = await loadStripe(process.env.GATSBY_STRIPE)

const Container = styledCompoent.div`
 
  padding: 20px;

   .cartContainer {
      box-shadow: 0px 3px 10px rgba(18, 42, 66, 0.08);
      
      height: calc(50% - 0.59em);
      overflow-y: auto;
      overflow-x: hidden;
      padding: 10px;
       ::-webkit-scrollbar {
          width: 12px;
      }

      ::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
          border-radius: 10px;
      }

      ::-webkit-scrollbar-thumb {
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
      }

      .carttotal {
        display: flex;
        justify-content: end;
        gap: 15px;
        
        h4 {
           font-weight: bold;
        }
      }
    }

    button {
    font-size: clamp(1rem, 2.5vh, 1.5rem);
    font-weight: 500;
    background-color: #f360a6;
    color: white;
    padding: 15px 20px;
    outline: none;
    border-radius: 4px;
    border: none;
    margin-top: 10px;
    cursor: pointer;
  }

  
    .cartcontent {
      width: 100%;
      display: flex;
      gap: 12px;
      padding: 5px;
     
    }
  
     h4,
    h5 {
      margin: 0 !important;
    }
 
.contactItem,
.cartitem,
.paymentMethod {
margin 20px 0;
}

`

const loader = "auto"
const appearance = {
  theme: "stripe",
  variables: {
    fontWeightNormal: "500",
    borderRadius: "2px",
    colorPrimary: "#f360a6",
    tabIconSelectedColor: "#fff",
    gridRowSpacing: "16px",
  },
  rules: {
    ".Tab, .Input, .Block, .CheckboxInput, .CodeInput": {
      boxShadow: "0px 3px 10px rgba(18, 42, 66, 0.08)",
    },
    ".Block": {
      borderColor: "transparent",
    },
    ".BlockDivider": {
      backgroundColor: "#ebebeb",
    },
    ".Tab, .Tab:hover, .Tab:focus": {
      border: "0",
    },
    ".Tab--selected, .Tab--selected:hover": {
      backgroundColor: "#f360a6",
      color: "#fff",
    },
  },
}

function Payment({ gatewayId }) {
  const [clientSecret, setClientSecret] = useState({
    secret: "",
    loading: true,
  })
  const { userCart, total } = useSelector(state => state.page)
  const [formdata, setFormdata] = useState({
    email: "",
    name: "",
    phone: "",
    country: "",
    city: "",
    addressone: "",
    addresstwo: "",
    zip: "",
    state: "",
  })

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads

    if (total) {
      const price = total
        ?.toLocaleString("en", { minimumFractionDigits: 2 })
        .replace(/[^a-zA-Z0-9]/g, "")

      const data = {
        amount: price,
        applicationFeeAmount: 0,
        automaticPaymentMethods: {
          enabled: false,
          allow_redirects: "never",
        },
        currency: "usd",
        tenant: process.env.GATSBY_BUILDSITE,
      }

      https(
        `${API_URL}/open/commerce/stripe/payment_intent`,
        "post",
        data,
        undefined,
        res => {
          setClientSecret({
            loading: false,
            secret: res.data.clientSecret,
          })
        },
        err => {
          setClientSecret({
            loading: false,
            secret: "",
          })
        }
      )
    }
  }, [total])


  if (clientSecret.loading) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div class="spinner text-primary" role="status"></div>
      </div>
    )
  }

  if (!clientSecret.secret && !clientSecret.loading) {
    return (
      <div>
        <h2>Unable to process checkout</h2>
      </div>
    )
  }

  return (
    // <Suspense fallback={<div />}>
    <Container className="">
      {clientSecret.secret && stripePromise && (
        <div className="row">
          <Elements
            stripe={stripePromise}
            options={{ clientSecret: clientSecret.secret, appearance, loader }}
          >
            <h1>Payment</h1>

            <div className="address col-lg-6 col-md-12">
              <h3>Shipping</h3>
              <AddressElement
                onChange={event => {
                  setFormdata(p => ({
                    ...p,
                    name: event.value?.name,
                    phone: event.value.phone,
                    country: event.value.address.country,
                    city: event.value.address.city,
                    addressone: event.value.address.line1,
                    addresstwo: event.value.address.line2,
                    zip: event.value.address.postal_code,
                    state: event.value.address.state,
                    firstname: event.value.firstName,
                  }))
                }}
                options={{
                  mode: "shipping",
                  blockPoBox: true,
                  fields: {
                    phone: "always",
                    firstName: "always",
                    lastName: "always",
                  },
                  validation: {
                    phone: {
                      required: "never",
                    },
                  },
                }}
              />
              <div className="contactItem">
                <h3>Contact info</h3>
                <LinkAuthenticationElement
                  onChange={event => {
                    setFormdata(p => ({
                      ...p,
                      email: event.value?.email,
                    }))
                  }}
                />
              </div>
            </div>
            <div className="cartitem  col-lg-6 col-md-12">
              <h3>Cart</h3>
              <div className="cartContainer">
                {userCart.length > 0 &&
                  userCart.map(item => {
                    if (item === null) return
                    let subprice = parseFloat(item?.price)
                      ? parseFloat(item?.price) * item?.quantity
                      : 0
                    return (
                      <div className="cartcontent" key={item.code}>
                        <div>
                          <img src={item.image} width={"50px"} />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                          }}
                        >
                          <h4>{item.title}</h4>
                          <div>
                            <span>Amount</span>
                            <h5>{item.quantity + " " + "x" + item.price}</h5>
                            <h5>{subprice}</h5>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                <div className="carttotal">
                  <h4>Total:</h4>
                  <h4>${total}</h4>
                </div>
              </div>
              <div className="paymentMethod">
                <h3>Payment Method</h3>
                <CheckoutForm userCart={userCart} formdata={formdata} gatewayId={gatewayId} />
              </div>
            </div>
          </Elements>
        </div>
      )}
    </Container>
    // </Suspense>
  )
}

export default Payment
